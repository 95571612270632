import React, { useState } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Loading from "./components/Utility/Loading.jsx";
import Toast from "./components/Utility/Toast.jsx";
import Modal from "./components/Utility/Modal.jsx";
import history from "./utils/history";
import PubSub from "pubsub-js";

// pages
import Index from "./views/pages/Index.jsx";
import Features from "./views/pages/Features.jsx";
import Pricing from "./views/pages/Pricing.jsx";
import Contact from "./views/pages/Contact.jsx";
import PrivacyPolicy from "./views/pages/PrivacyPolicy.jsx";
import TermsAndConditions from "./views/pages/TermsAndConditions.jsx";
import Page from "./views/pages/Page.jsx";

const App = () => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ alert, setAlert ] = useState(null);
  const [ alertVisible, setAlertVisible ] = useState(false);
  const [ modal, setModal ] = useState(null);

  const alertSubscriber = (msg, data) => {
    setAlert(data);
    setAlertVisible(true);

    setTimeout(() => {
      setAlertVisible(false);
    }, 3000);
  };
  const alertToken = PubSub.subscribe('alert', alertSubscriber);

  const loadingSubscriber = (msg, data) => {
    setIsLoading(data);
  };
  const loadingToken = PubSub.subscribe('loading', loadingSubscriber);

  const modalSubscriber = (msg, data) => {
    setModal(data);
  };
  const modalToken = PubSub.subscribe('modal', modalSubscriber);

  /**
   * This condition forces a redirect if the invited url qith wuery strings exists with a valid application id
   * DEPENDACY: application break feature. This way an application can be retrieved easily 
   */
  // if (applicationId) {
  //   return history.push(`application/${applicationId}?disabled=${disabled}`)
  // }
  // if (isAuthenticated && applicationId) {
  //   history.push(`/application/${applicationId}`)
  //   // return history.push(`application/${applicationId}?disabled=${disabled}`)
  // } 
  // else if (!isAuthenticated && applicationId) {
  //   loginWithRedirect({
  //     redirect_uri: window.location.href
  //   });
  // }

  return (
    <>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Index} />
          <Route exact path="/features" component={Features} />
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-of-use" component={TermsAndConditions} />
          <Route exact path="/:url" component={Page} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
      {isLoading && (
        <Loading />
      )}
      {alert && (
        <Toast visible={alertVisible} alert={alert} setAlertVisible={setAlertVisible} />
      )}
      {modal && (
        <Modal modal={modal} />
      )}
    </>
  )
};

export default App;
