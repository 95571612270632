import React, { useEffect, useState } from "react";
import Typist from 'react-typist';
import { Animated } from 'react-animated-css';
import { Helmet } from 'react-helmet';

import Header from "../../components/Header.jsx";
import Footer from "../../components/Footer.jsx";
import Features from "../../components/Features.jsx";

const FeaturesPage = () => {
  return (
    <>
      <Helmet>
        <title>FuzzLink | Features</title>
        <meta name="description" content="The only link for your social media bio. FuzzLink is a fully customizable page to easily showcase all of your most important links. Tracks how many times your links are clicked with our intuitive analytics dashboard. Add your FuzzLink to your Instagram, Twitter, or TikTok bio." />
      </Helmet>

      <Header />

      <section id="features">
        <Features />
      </section>

      <Footer />
    </>
  );
}

export default FeaturesPage;