import React from "react";
import Loader from 'react-loader-spinner';

const Loading = () => (
  <div className="custom-loading-modal" style={{ zIndex: 12 }}>
    <div className="custom-loading-modal-content">
      <Loader type="TailSpin" color="#0082ca" height={80} width={80} />
      <p>Loading...</p>
    </div>
  </div>
);

export default Loading;
