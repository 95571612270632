import config from "../config/index";

const handleResponse = async (response) => {
  const string = await response.text();
  let json = {};
  if (typeof string === 'string' && string.trim() !== '') {
    try {
      json = JSON.parse(string);
    } catch(err) {
      console.log('Invalid error received from server: ', err);
    }
  }

  if (response.status === 200) {
    // success
    // console.log('success: ', json);
    return json;
  } else {
    // error
    console.log('error: ', json);
    throw errorHandler(json);
  }
}

const errorHandler = (err) => {
  let message = 'There was a problem with your request.';

  if (typeof err === 'string') {
    message = err;
  } else if (err.errors && err.errors.message) {
    message = err.errors.message;
  } else if (typeof message !== 'string') {
    message = JSON.stringify(message);
  }

  return message;
}

const getPageByUrl = async (pageUrl) => {
  const url = `${config.apiUrl}/public/${pageUrl}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return await handleResponse(response);
}

const getLinks = async (pageId) => {
  const url = `${config.apiUrl}/public/${pageId}/links`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return await handleResponse(response);
}

const trackEvent = async (uuid, pageId, eventType, linkId, session) => {
  const url = `${config.apiUrl}/events`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      sessionId: uuid,
      pageId: pageId,
      eventType: eventType,
      referrer: document.referrer,
      linkId: linkId || null,
      session: session || null
    })
  });
  return await handleResponse(response);
}

export default {
  getPageByUrl,
  getLinks,
  trackEvent
};
