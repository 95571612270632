import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './tailwind.output.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-typist/dist/Typist.css";
// plugins styles downloaded
// import "./assets/vendor/fullcalendar/dist/fullcalendar.min.css";
// import "./assets/vendor/sweetalert2/dist/sweetalert2.min.css";
// import "./assets/vendor/select2/dist/css/select2.min.css";
// import "./assets/vendor/quill/dist/quill.core.css";
// import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/fontawesome.min.css";
// core styles
import "./assets/scss/fidddle.scss";
//import "./assets/css/inter.css";

ReactDOM.render(
  <App />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
