
let config = {}

const defaultConfig = {
  statusCodes: {
    ESIGNSENT: "ESIGNSENT",
    ESIGNCOMPLETED: "ESIGNCOMPLETED"
  }
}

switch(process.env.NODE_ENV) {
  case "production":
    config = {
      apiUrl : "https://api.fuzz.link"
    }
    break;
  default:
    config = {
      apiUrl : "http://localhost:4000/dev"
    }
}
config = Object.assign({}, defaultConfig, config);

export default config;