import React, { useEffect, useState } from "react";
import Typist from 'react-typist';
import { Animated } from 'react-animated-css';

import Header from "../../components/Header.jsx";
import Footer from "../../components/Footer.jsx";
import Pricing from "../../components/Pricing.jsx";
import Features from "../../components/Features.jsx";
import Contact from "../../components/Contact.jsx";

const STRIPE_PRICE_ID_FREE = 'price_1HFTILBeHyFamaEwr6BpOKBI';
const STRIPE_PRICE_ID_PRO_MONTH = 'price_1I6JP4BeHyFamaEwZLxSjBaC';
const STRIPE_PRICE_ID_PRO_YEAR = 'price_1HFTIMBeHyFamaEwHxnwD4ys';

const fuzzLink = require('assets/img/fuzzlink-iphone-mockup.png');
const chadTheCat = require('assets/img/cat-iphone-mockup.png');
const happyHannah = require('assets/img/happyhannah-iphone-mockup.png');
const tacoTown = require('assets/img/taco-town-iphone-mockup.png');

const Index = () => {
  const [ pricingPage, setPricingPage ] = useState('yearly');
  const [ menuOpen, setMenuOpen ] = useState(false);
  const [ screenshotUrl, setScreenshotUrl ] = useState(false);
  const [ showScreenshot, setShowScreenshot ] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowScreenshot(false);
    }, 2800);

    setTimeout(() => {
      setScreenshotUrl(chadTheCat);
      setTimeout(() => {
        setShowScreenshot(true);
      }, 200);
    }, 3700);

    setTimeout(() => {
      setShowScreenshot(false);
    }, 6300);

    setTimeout(() => {
      setScreenshotUrl(happyHannah);
      setTimeout(() => {
        setShowScreenshot(true);
      }, 200);
    }, 8300);

    setTimeout(() => {
      setShowScreenshot(false);
    }, 10900);

    setTimeout(() => {
      setScreenshotUrl(tacoTown);
      setTimeout(() => {
        setShowScreenshot(true);
      }, 200);
    }, 12750);
  }, []);

  const goToSignup = (plan) => {
    // google tag manager custom event
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'signupStarted',
      'plan': plan
    });

    window.location.href = `https://app.fuzz.link/signup?plan=${plan === STRIPE_PRICE_ID_FREE ? 'basic' : plan === STRIPE_PRICE_ID_PRO_MONTH ? 'pro_month' : 'pro_year'}`;
  }

  return (
    <>
      <Header />
      
      <section id="home">
        <div className="pt-0 pb-8 bg-gray-50 sm:pt-12 md:pt-8">
          <div className="mx-auto px-4 sm:px-6">
            <div className="text-center">
              <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                Your bio's best friend.
                <br />
                <span className="text-indigo-600">Totally free.</span>
              </h2>
              <p className="mt-3 mb-4 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                With FuzzLink, you can make all of your important links easily accessible through one custom URL.
              </p>

              <button
                className="flex items-center px-4 py-3 my-4 mx-auto border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                type="button"
                onClick={e => goToSignup(STRIPE_PRICE_ID_FREE)}
              >
                <svg className="h-6 w-6 text-white mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
                GET STARTED FOR FREE
              </button>


              <h3 className="mt-4 lg:mt-14 px-2 break-all text-2xl tracking-tight leading-10 font-semibold text-gray-900 sm:text-3xl sm:leading-none md:text-4xl">
                <Typist
                  className="TypistExample-message"
                  cursor={{ hideWhenDone: false }}
                >
                  fuzz.link/<Typist.Delay ms={1800} /><span className="text-indigo-600">chad<Typist.Delay ms={200} />the<Typist.Delay ms={200} />cat
                  <Typist.Backspace count={10} delay={2000} />
                  <Typist.Delay ms={750} />
                  happy<Typist.Delay ms={200} />hannah
                  <Typist.Backspace count={11} delay={2000} />
                  <Typist.Delay ms={750} />
                  taco<Typist.Delay ms={200} />town
                  <Typist.Delay ms={750} />
                  </span>
                </Typist>
              </h3>

              <div className="relative mt-8">
                <div className="absolute inset-0 flex flex-col -mx-4 sm:-mx-6">
                  <div className="flex-1"></div>
                  <div className="flex-1 w-full bg-gray-800"></div>
                </div>
                <div className="max-w-screen-sm mx-auto px-4 sm:px-18 md:px-20 lg:px-24 pb-20">
                  <Animated animationIn="fadeIn" animationInDuration="1000" animationOut="fadeOut" animationOutDuration="1000" isVisible={showScreenshot}>
                    <img className="relative object-contain w-full" src={screenshotUrl || fuzzLink} alt="FuzzLink" />
                  </Animated>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section id="features" className="bg-gray-50 pt-12 sm:pt-18">
        <Features />
      </section>
      
      <section id="pricing" className="bg-gray-50 pt-12 sm:pt-18">
        <Pricing />
      </section>

      <section id="contact" className="bg-gray-50 pt-12 sm:pt-18">
        <Contact />
      </section>

      <Footer />
    </>
  );
}

export default Index;