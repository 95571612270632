import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "routes.js";
import history from "utils/history";

const STRIPE_PRICE_ID_FREE = 'price_1HFTILBeHyFamaEwr6BpOKBI';
const STRIPE_PRICE_ID_PRO_MONTH = 'price_1I6JP4BeHyFamaEwZLxSjBaC';
const STRIPE_PRICE_ID_PRO_YEAR = 'price_1HFTIMBeHyFamaEwHxnwD4ys';

const Header = (props) => {
  const [ menuOpen, setMenuOpen ] = useState(false);

  const goToSignup = (plan) => {
    // google tag manager custom event
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'signupStarted',
      'plan': plan
    });

    window.location.href = `https://app.fuzz.link/signup?plan=${plan === STRIPE_PRICE_ID_FREE ? 'basic' : plan === STRIPE_PRICE_ID_PRO_MONTH ? 'pro_month' : 'pro_year'}`;
  }

  const goToLink = (path) => {
    history.push(path);
    setMenuOpen(false);
  };
  
  return (
    <>
      <section id="home">
        <div className="bg-gray-50">
          <div className="relative">
            <div className="relative pt-6 pb-8">
              <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
                <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
                  <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                    <div className="flex items-center justify-between w-full md:w-auto">
                      <a href="#" aria-label="Home">
                        <img className="h-8 w-auto sm:h-10" src={require("assets/img/brand/logo-horizontal-on-white.png")} alt="FuzzLink" />
                      </a>
                      <div className="-mr-2 flex items-center md:hidden">
                        <button onClick={e => setMenuOpen(true)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" id="main-menu" aria-label="Main menu" aria-haspopup="true">
                          <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:flex md:space-x-10">
                    <a onClick={e => goToLink('/')} href="#" className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Home</a>
                    <a onClick={e => goToLink('/features')} href="#" className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Features</a>
                    <a onClick={e => goToLink('/pricing')} href="#" className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Pricing</a>
                    <a onClick={e => goToLink('/contact')} href="#" className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Contact Us</a>
                  </div>
                  <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                    <span className="inline-flex rounded-md shadow mr-4">
                      <a href="https://app.fuzz.link/login" className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-gray-50 active:text-indigo-700 transition duration-150 ease-in-out">
                        Log in
                      </a>
                    </span>
                    <span className="inline-flex rounded-md shadow">
                      <a href="#" onClick={e => goToSignup(STRIPE_PRICE_ID_FREE)} className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:text-gray-50 focus:outline-none focus:shadow-outline-indigo transition duration-150 ease-in-out">
                        SIGN UP FREE
                      </a>
                    </span>
                  </div>
                </nav>
              </div>

              
                {/* Mobile menu, show/hide based on menu open state.

                Entering: "duration-150 ease-out"  
                  From: "opacity-0 scale-95"
                  To: "opacity-100 scale-100"
                Leaving: "duration-100 ease-in"
                  From: "opacity-100 scale-100"
                  To: "opacity-0 scale-95" */}
              
              {menuOpen && (
                <div className="absolute top-0 inset-x-0 p-2 transition transform z-10 origin-top-right md:hidden">
                  <div className="rounded-lg shadow-md">
                    <div className="rounded-lg bg-white shadow-xs overflow-hidden" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
                      <div className="px-5 pt-4 flex items-center justify-between">
                        <div>
                          <img className="h-8 w-auto" src={require("assets/img/brand/logo-horizontal-on-white.png")} alt="" />
                        </div>
                        <div className="-mr-2">
                          <button onClick={e => setMenuOpen(false)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" aria-label="Close menu">
                            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div className="px-2 pt-2 pb-3">
                        <a onClick={e => goToLink('/')} href="#" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Home</a>
                        <a onClick={e => goToLink('/features')} href="#" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Features</a>
                        <a onClick={e => goToLink('/pricing')} href="#" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Pricing</a>
                        <a onClick={e => goToLink('/contact')} href="#" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Contact Us</a>
                      </div>
                      <div>
                        <a href="https://app.fuzz.link/login" className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out" role="menuitem">
                          Log in
                        </a>
                      </div>
                      <div>
                        <a href="#" onClick={e => goToSignup(STRIPE_PRICE_ID_FREE)} className="block w-full px-5 py-3 text-center font-medium text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none transition duration-150 ease-in-out" role="menuitem">
                          SIGN UP FREE
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}


export default Header;
