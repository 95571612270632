import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';

import Header from "../../components/Header.jsx";
import Footer from "../../components/Footer.jsx";
import Pricing from "../../components/Pricing.jsx";

const PricingPage = () => {
  return (
    <>
      <Helmet>
        <title>FuzzLink | Pricing</title>
        <meta name="description" content="Basic Plan - free for life. Pro Plan - $2.50/mo billed yearly or $4/mo billed monthly." />
      </Helmet>

      <Header />
      
      <section id="pricing">
        <Pricing />
      </section>

      <Footer />
    </>
  );
}

export default PricingPage;