import React, { useEffect, useState } from "react";
import Typist from 'react-typist';
import { Animated } from 'react-animated-css';

const STRIPE_PRICE_ID_FREE = 'price_1HFTILBeHyFamaEwr6BpOKBI';
const STRIPE_PRICE_ID_PRO_MONTH = 'price_1I6JP4BeHyFamaEwZLxSjBaC';
const STRIPE_PRICE_ID_PRO_YEAR = 'price_1HFTIMBeHyFamaEwHxnwD4ys';

const Contact = () => {
  const goToSignup = (plan) => {
    // google tag manager custom event
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'signupStarted',
      'plan': plan
    });

    window.location.href = `https://app.fuzz.link/signup?plan=${plan === STRIPE_PRICE_ID_FREE ? 'basic' : plan === STRIPE_PRICE_ID_PRO_MONTH ? 'pro_month' : 'pro_year'}`;
  }

  const openEmail = () => {
    window.open('mailto:info@fuzz.link', '_blank');
  }

  return (
    <>
      <div className="pb-12 bg-gray-50 lg:pt-8">
        <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="text-4xl leading-none font-extrabold text-gray-900 sm:text-center">Contact Us</h1>
          <p className="mt-5 text-xl leading-7 text-gray-500 sm:text-center">Please send all questions, comments, and concerns to <a onClick={openEmail} href="#" class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">info@fuzz.link</a></p>
        </div>
      </div>
    </>
  );
}


export default Contact;