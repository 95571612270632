import React, { useEffect, useState } from "react";
import Typist from 'react-typist';
import { Animated } from 'react-animated-css';
import { Helmet } from 'react-helmet';

import Header from "../../components/Header.jsx";
import Footer from "../../components/Footer.jsx";
import Contact from "../../components/Contact.jsx";

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>FuzzLink | Contact Us</title>
        <meta name="description" content="The only link for your social media bio. FuzzLink is a fully customizable page to easily showcase all of your most important links. Tracks how many times your links are clicked with our intuitive analytics dashboard. Add your FuzzLink to your Instagram, Twitter, or TikTok bio." />
      </Helmet>

      <Header />

      <section id="contact">
        <Contact />
      </section>

      <Footer />
    </>
  );
}

export default ContactPage;